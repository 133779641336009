import React from "react";
import Skeleton from 'react-loading-skeleton';

const PenaltyUnitsCalculatorResultSkeleton = () =>
{
  return (
    <div className = "h-56 w-full">
      <Skeleton className = "h-full" />
    </div>
  );
};

export default PenaltyUnitsCalculatorResultSkeleton;