export const getJurisdictionQueryString = (j) =>
{
  if (typeof window !== "undefined" && window.history.state?.j)
  {
    return window.history.state.j;
  }

  return j;
};

export const getMaximumPenaltyUnitsQueryString = (mpu) =>
{
  if (typeof window !== "undefined" && window.history.state?.mpu)
  {
    return window.history.state.mpu;
  }

  return mpu;
};

export const getFinancialYearQueryString = (fy) =>
{
  if (typeof window !== "undefined" && window.history.state?.fy)
  {
    return window.history.state.fy;
  }

  return fy;
};