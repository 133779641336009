import React from "react";
import { Link } from "gatsby";
import { getFinancialYearFirstYear } from "../../utils/helpers";
import Moment from "moment";

class PenaltyUnitsCalculatorResult extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  componentDidMount()
  {
    window.onpopstate = this.onBackOrForwardButtonEvent;
  }

  componentWillUnmount()
  {
    window.onpopstate = () => {};
  }

  onBackOrForwardButtonEvent = (e) =>
  {
    const historyState =
    {
      j: this.props.jurisdictionQueryString,
      mpu : this.props.maximumPenaltyUnitsQueryString,
      fy: this.props.financialYearQueryString
    };

    window.history.pushState(
      historyState
      , ""
      , `/tools${ this.props.isWidget ? "/widgets" : "" }/penaltyunits?j=${ this.props.jurisdictionQueryString }&mpu=${ this.props.maximumPenaltyUnitsQueryString }&fy=${ this.props.financialYearQueryString }`
    );
  };

  render()
  {
    const financialYearFirstDay = (this.props.financialYearQueryString && this.props.financialYearQueryString !== null) ? Moment(`${this.props.financialYearQueryString}-07-01`, 'YYYY-MM-DD') : null;
  
    const jurisdiction = this.props.jurisdictionsQueryResults.data.allJurisdictions.find(e => e.name === this.props.jurisdictionQueryString);
  
    const relevantPums = [];
  
    const possiblePums = jurisdiction.penaltyUnitMultipliers
      .sort((a, b) => Moment(a.commencementDate, 'YYYY-MM-DD') - Moment(b.commencementDate, 'YYYY-MM-DD'));
  
    for (let i = 0; i < possiblePums.length; ++i)
    {
      const pum = possiblePums[i];
      const pumcommencementDate = Moment(pum.commencementDate, 'YYYY-MM-DD');
  
      if (getFinancialYearFirstYear(pum.commencementDate) === Number(this.props.financialYearQueryString))
      {
        if (i >= 1 && relevantPums.length < 1 && !pumcommencementDate.isSame(financialYearFirstDay))
        {
          relevantPums.push(possiblePums[i - 1]);
        }
  
        relevantPums.push(pum);
      }
    }
  
    if (relevantPums.length < 1)
    {
      const precedingPums = possiblePums.filter(pum => getFinancialYearFirstYear(pum.commencementDate) <= Number(this.props.financialYearQueryString));
      relevantPums.push(precedingPums[precedingPums.length - 1]);
    }
  
    if (relevantPums.length < 1)
    {
      // TODO ?
    }
  
    return (
      <div>
        <div
          className = "p-2 bg-gray-300"
        >
          <p>
            Within the legal jurisdiction of
            { ' ' }
            <strong
              className = "font-semibold italic"
            >
              { jurisdiction ? jurisdiction.name : "<unknown>" }
            </strong>
            , an offence taking place in the
            { ' ' }
            <strong
              className = "font-semibold italic"
            >
              { `${ this.props.financialYearQueryString }/${ (Number(this.props.financialYearQueryString) + 1).toString().slice(-2) }` }
            </strong>
            { ' ' }
            financial year with a maximum penalty unit count of
            { ' ' }
            <strong
              className = "font-semibold italic"
            >
              { (this.props.maximumPenaltyUnitsQueryString && this.props.maximumPenaltyUnitsQueryString !== null) ? this.props.maximumPenaltyUnitsQueryString.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0" }
            </strong>
            { ' ' }
            could result in a maximum fine of:
          </p>
          {
            (() =>
            {
              const options = [];
              for (let i = 0; i < relevantPums.length; ++i)
              {
                const p = relevantPums[i];

                if (!p || p === null)
                {
                  return;
                }

                options.push(
                  <div
                    key = { p.commencementDate }
                    className = "w-full px-3 py-3"
                  >
                    <h1
                      className = "font-bold text-4xl"
                    >
                      $
                      { ' ' }
                      {
                        (p ? p.value * this.props.maximumPenaltyUnitsQueryString : "<unknown>").toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) 
                      }
                    </h1>
                    {
                      (() =>
                      {
                        const c = Moment(p.commencementDate, 'YYYY-MM-DD');

                        if (relevantPums.length === 1 && (getFinancialYearFirstYear(p.commencementDate) !== Number(this.props.financialYearQueryString) || c.isSame(financialYearFirstDay)))
                        {
                          return(
                            <p></p>
                          );
                        }

                        if (c.isBefore(financialYearFirstDay))
                        {
                          const p2 = relevantPums[i + 1];
                          const c2 = Moment(p2.commencementDate, 'YYYY-MM-DD').add(-1, 'days');
                          return (
                            <p>
                              { `(On or after July 1st ${this.props.financialYearQueryString} - ${c2.format('MMMM Do YYYY')})` }
                            </p>
                          );
                        }
                        else
                        {
                          let endDate = "";
                          if (typeof relevantPums[i + 1] === 'undefined')
                          {
                            // last...
                            endDate = `June 30 ${ Number(this.props.financialYearQueryString) + 1 }`;
                          }
                          else
                          {
                            // middle...
                            const p2 = relevantPums[i + 1];
                            const c2 = Moment(p2.commencementDate, 'YYYY-MM-DD').add(-1, 'days');
                          
                            endDate = c2.format('MMMM Do YYYY');
                          }

                          return (
                            <p>
                              (On or after
                              { ' ' }
                              { c.format('MMMM Do YYYY') }
                              { ' ' }
                              -
                              { ' ' }
                              { endDate }
                              )
                            </p>
                          );
                        }
                      })()
                    }
                    <div
                      className = "text-sm"
                    >
                      {
                        (p && p.authorityName !== "") ?
                          <p>
                            Refer to the relevant authority document:
                            { ' ' }
                            {
                              p.authorityLink === "" ?
                              p.authorityName :
                              <a
                                className = "font-semibold italic"
                                href = { p.authorityLink }
                                target = "_blank"
                                rel = "noopener noreferrer"
                              >
                                { p.authorityName }
                              </a>
                            }
                            .
                          </p>
                        :
                          <p>
                            Currently, we don't have the relevant Authority Document for this time period in our records.
                          </p>
                      }
                    </div>
                    {
                      relevantPums.length != 1 &&
                      <hr
                        className = "border-solid border border-gray-600 my-3 mx-64"
                      />
                    }
                  </div>
                );
              }

              return (
                <div
                  className = "flex flex-wrap"
                >
                  { options }
                </div>
              );
            })()
          }
          <div
            className = "my-6"
          >
            <Link
              to = { `/tools${ this.props.isWidget ? "/widgets" : "" }/penaltyunits?j=${ this.props.jurisdictionQueryString }&mpu=${ this.props.maximumPenaltyUnitsQueryString }&fy=${ this.props.financialYearQueryString }` }
              className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold m-3 px-4 py-2 rounded text-sm text-white"
            >
              New Calculation
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PenaltyUnitsCalculatorResult;