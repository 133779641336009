import React from "react";
import ToolsWidgetLayout from "../../../../components/toolsWidgetLayout";
import PenaltyUnitsCalculatorResult from "../../../../components/penaltyUnits/penaltyUnitsCalculatorResult";
import Apollo from "../../../../components/apollo";
import { penaltyUnitCalculatorQuery } from "../../../../utils/staticQueryFragments";
import { getJurisdictionQueryString, getMaximumPenaltyUnitsQueryString, getFinancialYearQueryString } from "../../../../utils/penaltyUnitCalculatorHelper"; 
import SEO from "../../../../components/seo";
import WithLocation from "../../../../components/withLocation";
import PenaltyUnitsCalculatorResultSkeleton from "../../../../components/penaltyUnits/penaltyUnitsCalculatorResultSkeleton";

class ToolsWidgetsPenaltyUnitsPage extends React.Component
{
  constructor(props)
  {
    super(props);
  }

  render()
  {
    return (
      <ToolsWidgetLayout>
        <SEO
          keywords = { [`LawChest`, `penalty`, `unit`, `calculator`] }
          title = "Penalty Unit Calculator"
        />
        <section
          id = "utilitySection"
          className = "text-center"
        >
          <Apollo
            propsToInsert =
            {
              (queryResults) => (
                { jurisdictionsQueryResults: queryResults }
              )
            }
            query = { penaltyUnitCalculatorQuery }
            queryOptions = { { notifyOnNetworkStatusChange: true } }
            loadingComponent = { PenaltyUnitsCalculatorResultSkeleton }
          >
            <PenaltyUnitsCalculatorResult
              isWidget = { true }
              jurisdictionQueryString = { getJurisdictionQueryString(this.props.search.j) }
              maximumPenaltyUnitsQueryString = { getMaximumPenaltyUnitsQueryString(this.props.search.mpu) }
              financialYearQueryString = { getFinancialYearQueryString(this.props.search.fy) }
            />
          </Apollo>
        </section>
      </ToolsWidgetLayout>
    );
  }
}

export default WithLocation(ToolsWidgetsPenaltyUnitsPage);
